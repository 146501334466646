import {Dispatch, SetStateAction} from 'react';
import {Box, VStack} from '@chakra-ui/react';
import {BurgerBar} from './BugerBar';

export const BurgerMenu: React.FC<{
  showTab: boolean
  setShowTab: Dispatch<SetStateAction<boolean>>
}> = ({showTab, setShowTab}) => (
  <Box 
    display={{base: 'block', md: 'none'}}
    margin="0 1.2em 0.5em"
    padding="0.3em"
    border="1px solid white"
    borderRadius="5px"
    backgroundColor="gray.archalpha"
    onClick={() => {
      setShowTab(!showTab);
    }}
  >
    <VStack
      alignItems="center"
      justifyContent="center"
      width="32px"
      height="30px"
    >
      <BurgerBar />
      <BurgerBar />
      <BurgerBar />

    </VStack>
  </Box>

);