import {Box} from '@chakra-ui/react';

export const BurgerBar = () => (
  <Box 
    width="100%"
    margin="auto"
    height="10px"
    backgroundColor="white"
    padding="0.1em 0"
    borderRadius="5px"
  />
);