// import './footer.css';

import {HStack, Image, Link, Text, VStack} from '@chakra-ui/react';

// export const Footer: React.FC = () => (
//   <footer className="footer">
//     <div>
//       <address className="item" id="adresse"><p>Hélène De Jesus Garcia<br />17250 Trizay</p></address>
//       <div className="item" id="email">
//         <a href="mailto:archange.gravure@wanadoo.fr">
//           <div style={{display: 'inline-flex', alignItems: 'center'}}>
//             <img src="images/email.png" alt="email" />
//             <span>archange.gravure@wanadoo.fr</span>
//           </div>
//         </a>
//       </div>
//       <div className="item" id="mobile">
//         <a href="tel:0615561880">
//           <div style={{display: 'inline-flex', alignItems: 'center'}}>
//             <img src="images/mobile.png" alt="mobile" /><span>06.15.56.18.80</span>
//           </div>
//         </a>
//       </div>
//       <div className="item" id="facebook">
//         <a href="https://www.facebook.com/Archange-Gravure-258722100835622/" target="_new">
//           <img src="images/facebook.png" alt="facebook" />
//         </a>
//       </div>
//     </div>
//   </footer>
// );

export const Footer: React.FC = () => (
  <>
    {/* Desktop */}
    <HStack 
      as="footer" 
      display={{base: 'none', md: 'flex'}}
      position="relative"
      background="url('../images/border.jpg') no-repeat fixed center/cover"
      minWidth="300px"
      width="100%"
      height="100px"
      alignItems="center"
      justifyContent="space-between"
    >
      <address>
        <Text 
          display={{base: 'none', md: 'block'}}
          textAlign="center"
          fontSize="1.5em"
          padding="0 2em"
          textShadow="1px 0 1px #ccc, -1px 0 1px #ccc, 0 1px 1px #ccc, 0 -1px 1px #ccc, 1px 1px 1px #ccc, -1px -1px 1px #ccc, 1px -1px 0 #ccc, -1px 1px 0 #ccc"
        >
        Hélène De Jesus Garcia<br />17250 Trizay
        </Text>
      </address>
      <Link href="mailto:archange.gravure@wanadoo.fr">
        <HStack
          gap="0.5em"
        >
          <Image src="/images/email.png" alt="email" width="60px" height="auto"/>
          <Text 
            fontSize="1.2em"
            textShadow="1px 0 1px #ccc, -1px 0 1px #ccc, 0 1px 1px #ccc, 0 -1px 1px #ccc, 1px 1px 1px #ccc, -1px -1px 1px #ccc, 1px -1px 0 #ccc, -1px 1px 0 #ccc"
          >archange.gravure@wanadoo.fr</Text>
        </HStack>
      </Link>
      <Link href="tel:0615561880">
        <HStack
          gap="0.5em"
        >
          <Image src="/images/mobile.png" alt="mobile" width="40px" height="auto"/>
          <Text 
            fontSize="1.2em"
            textShadow="1px 0 1px #ccc, -1px 0 1px #ccc, 0 1px 1px #ccc, 0 -1px 1px #ccc, 1px 1px 1px #ccc, -1px -1px 1px #ccc, 1px -1px 0 #ccc, -1px 1px 0 #ccc"
          >06.15.56.18.80</Text>
        </HStack>
      </Link>
      <Link 
        href="https://www.facebook.com/Archange-Gravure-258722100835622/" 
        target="_new"
      >
        <Image src="/images/facebook.png" marginRight="1em"/>
      </Link>
    </HStack>

    {/* Mobile */}
    <VStack 
      as="footer" 
      display={{base: 'flex', md: 'none'}}
      position="relative"
      background="url('../images/border.jpg') no-repeat fixed center/cover"
      minWidth="300px"
      width="100%"
      height="75px"
      alignItems="center"
      justifyContent="flex-start"
    >
      <address>
        <Text 
          fontSize="1em"
          textShadow="1px 0 1px #ccc, -1px 0 1px #ccc, 0 1px 1px #ccc, 0 -1px 1px #ccc, 1px 1px 1px #ccc, -1px -1px 1px #ccc, 1px -1px 0 #ccc, -1px 1px 0 #ccc"
          width="100%"
        >
        Hélène De Jesus Garcia - 17250 Trizay
        </Text>
      </address>
      <HStack width="100%" justifyContent="space-evenly">
        <Link href="mailto:archange.gravure@wanadoo.fr">
          <HStack
            gap="0.5em"
            width="30%"
          >
            <Image src="/images/email.png" width="20px" height="auto"/>
            <Text 
              fontSize="0.75em"
              textShadow="1px 0 1px #ccc, -1px 0 1px #ccc, 0 1px 1px #ccc, 0 -1px 1px #ccc, 1px 1px 1px #ccc, -1px -1px 1px #ccc, 1px -1px 0 #ccc, -1px 1px 0 #ccc"
            >archange.gravure@wanadoo.fr</Text>
 
          </HStack>
        </Link>
        <Link href="tel:0615561880">
          <HStack
            gap="0.5em"
            width="30%"
          >
            <Image src="/images/mobile.png" width="20px" height="auto"/>
            <Text 
              fontSize="0.75em"
              textShadow="1px 0 1px #ccc, -1px 0 1px #ccc, 0 1px 1px #ccc, 0 -1px 1px #ccc, 1px 1px 1px #ccc, -1px -1px 1px #ccc, 1px -1px 0 #ccc, -1px 1px 0 #ccc"
            >06.15.56.18.80</Text>
 
          </HStack>
        </Link>
        <Link 
          href="https://www.facebook.com/Archange-Gravure-258722100835622/" 
          target="_new"
        >
          <Image src="/images/facebook.png" width="30px"/>
        </Link>

      </HStack>
    </VStack>
  </>
  
);