import {MenuRoute} from '@/components/common/Layout';
import {Box, Link} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {NavLink} from 'react-router-dom';

export const BurgerTabItem: React.FC<{
  item: MenuRoute,
  setShowTab: Dispatch<SetStateAction<boolean>>
}> = ({item, setShowTab}) => {
  const onClick = () => {
    setShowTab(false);
  };

  return (
    <Box
      as="li"
      textAlign="right"
      listStyleType="none"
      fontSize={{base: '1.3em'}}
      margin="auto"
      paddingTop="3em"
    >
      <Link
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        as={(props: any) => (
          <NavLink
            {...props}
            style={({isActive}) => {
              return {
                textShadow: isActive || (item.url === 'accueil' && location.pathname === '/') ? 
                  '1px 0 1px white, -1px 0 1px white, 0 1px 1px white, 0 -1px 1px white, 1px 1px 1px white, -1px -1px 1px white, 1px -1px 1px white, -1px 1px 1px white':
                  '2px 0 2px rgba(184, 174, 225, 0.5), -2px 0 2px rgba(184, 174, 225, 0.5), 0 2px 2px rgba(184, 174, 225, 0.5), 0 -2px 2px rgba(184, 174, 225, 0.5), 2px 2px 2px rgba(184, 174, 225, 0.5), -2px -2px 2px rgba(184, 174, 225, 0.5), 2px -2px 2px rgba(184, 174, 225, 0.5), -2px 2px 2px rgba(184, 174, 225, 0.5)',
                textDecoration: 'none',
                textAlign: 'right'
              };
            }}
          />
        )} 
        onClick={onClick} 
        color="purple.link"
        to={`/${item.url}`} 
      >
        {item.label}
      </Link>		    
    </Box>
  );
};
