import {MenuRoute} from '@/components/common/Layout';
import {Box, Slide} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {BurgerTabItem} from './BurgerTabItem';

export const BurgerTab: React.FC<{
  showTab: boolean,
  setShowTab: Dispatch<SetStateAction<boolean>>,
  items: MenuRoute[]
}> = ({
  showTab,
  setShowTab,
  items
}) => (
  <Slide direction='right' in={showTab} style={{
    zIndex: 10,
    top: '75px'
  }}>
    <Box 
      position="absolute"
      top={0}
      right={0}
      width="300px"
      height="calc(100% - 75px)"
      backgroundColor="gray.archalpha2"
    >
      <Box
        display={{base: 'block', md: 'none'}}
        width="80%"
        margin="auto"
        height="50%"
      >
        <Box
          as="ul"
        >
          {
            items.map((item, id) => (
              <BurgerTabItem key={id} item={item} setShowTab={setShowTab} />
            ))
          }
        </Box>
      </Box>

    </Box>
  </Slide>

);