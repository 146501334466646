import {Box} from '@chakra-ui/react';
import {H2} from '../common/H2';
import {Sep} from '../common/Sep';
import {VArticle} from '../common/VArticle';
import {funeraire} from '../images';
import {ImageRow} from '../common/ImageRow';
import {ZImage} from '../common/ZImage';

export const Funeraire = () => (
  <Box as="section" margin="2em auto">
    <VArticle>
      <H2>
        Je grave à la main le nom, le prenom,<br />les dates de naissance et de décés des défunts
      </H2>
      <ImageRow>
        <ZImage {...funeraire['img002']} />
        <ZImage {...funeraire['img009']} />
        <ZImage {...funeraire['img008']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['img010']} />
        <ZImage {...funeraire['img011']} />
        <ZImage {...funeraire['img012']} />
      </ImageRow>
      <Sep />
    </VArticle>
    <VArticle>
      <H2>
        Je peux personnaliser votre monument avec un décor réalisé de différentes façons
				suivant la couleur de la pierre, du granit, du marbre ...
      </H2>
      <Sep />
    </VArticle>
    <VArticle>
      <H2>Sur vase ou jardinière</H2>
      <ImageRow>
        <ZImage {...funeraire['img013']} />
        <ZImage {...funeraire['img014']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['img015']} />
        <ZImage {...funeraire['img016']} />
        <ZImage {...funeraire['img017']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['img018']} />
        <ZImage {...funeraire['img019']} />
      </ImageRow>
      <Sep />
    </VArticle>
    <VArticle>
      <H2>Sur plaque à poser ou de colombarium</H2>
      <ImageRow>
        <ZImage {...funeraire['imgn13']} />
        <ZImage {...funeraire['img020']} />
        <ZImage {...funeraire['img021']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['img022']} />
        <ZImage {...funeraire['img041']} />
        <ZImage {...funeraire['img023']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['img024']} />
        <ZImage {...funeraire['img025']} />
        <ZImage {...funeraire['img040']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['img044']} />
        <ZImage {...funeraire['plaque12']} />
      </ImageRow>
      <Sep />
    </VArticle>
    <VArticle>
      <H2>Sur stèle</H2>
      <ImageRow>
        <ZImage {...funeraire['img026']} />
        <ZImage {...funeraire['imgn07']} />
        <ZImage {...funeraire['imgn08']} />
        <ZImage {...funeraire['img027']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['img029']} />
        <ZImage {...funeraire['imgn02']} />
        <ZImage {...funeraire['img028']} />
        <ZImage {...funeraire['imgn14']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['imgn09']} />
        <ZImage {...funeraire['imgn15']} />
        <ZImage {...funeraire['imgn18']} />
        <ZImage {...funeraire['imgn10']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['imgn19']} />
        <ZImage {...funeraire['imgn11']} />
        <ZImage {...funeraire['imgn03']} />
        <ZImage {...funeraire['imgn21']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['imgn24']} />
        <ZImage {...funeraire['imgn25']} />
        <ZImage {...funeraire['imgn22']} />
        <ZImage {...funeraire['imgn26']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['totem']} />
        <ZImage {...funeraire['img039']} />
      </ImageRow>
      <Sep />
    </VArticle>
    <VArticle>
      <H2>
        La restauration des lettres ou rechampissage consiste à repeindre ou déposer de la feuille d'or 
        au creux des lettres qui se sont effacées avec le temps.<br/>Il est parfois nécessaire de les regraver
      </H2>
      <ImageRow>
        <ZImage {...funeraire['img030']} />
        <ZImage {...funeraire['img034']} />
        <ZImage {...funeraire['img038']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['img032']} />
        <ZImage {...funeraire['img033']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['img042']} />
        <ZImage {...funeraire['img043']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...funeraire['img036']} />
        <ZImage {...funeraire['img037']} />
        <ZImage {...funeraire['img031']} />
      </ImageRow>
    </VArticle>
  </Box>
);