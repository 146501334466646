import {Box, HStack, Image, VStack} from '@chakra-ui/react';
import {BurgerMenu} from './Burger/BurgerMenu';
import {Dispatch, SetStateAction} from 'react';
import {Menu} from './Menu';
import {MenuRoute} from '../common/Layout';

export const Header: React.FC<{
  logo: string, 
  title: string, 
  items: MenuRoute[],
  showTab: boolean,
  setShowTab: Dispatch<SetStateAction<boolean>>
}> = ({
  logo,
  title,
  items,
  showTab,
  setShowTab
}) => {
  return (
    <HStack 
      as="header" 
      background="url('../images/border.jpg') no-repeat fixed center/cover"
      minWidth="300px"
      width="100%"
      height={{base: '75px', md: '100px'}}
      justifyContent="space-between"
    >
      <Image 
        src={logo} 
        alt={title}
        title={title}
        width="auto"
        height={{base: '75%', md: '100%'}}
      />
      <VStack
        position="relative"
        height="100%"
        flex="1"
        justifyContent="space-around"
        alignItems={{base: 'flex-end', md: 'center'}}
      >
        <Box
          paddingRight={{base: '1em', md: '0'}}
          color="gray.archange"
          height={{base: '22px', md: '50%'}}
          fontSize={{base: '1em', md: '2.1em'}}
          textShadow="1px 0 1px #ccc, -1px 0 1px #ccc, 0 1px 1px #ccc, 0 -1px 1px #ccc, 1px 1px 1px #ccc, -1px -1px 1px #ccc, 1px -1px 0 #ccc, -1px 1px 0 #ccc"
          textAlign="right"
        >
          {title}
        </Box>
        {/* Menu tablet et screen */}
        <Menu items={items} />
        {/* Menu mobile */}
        <BurgerMenu showTab={showTab} setShowTab={setShowTab} />
      </VStack>
    </HStack>
  );
};
