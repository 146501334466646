import {Box} from '@chakra-ui/react';
import {ReactNode} from 'react';

export const H2: React.FC<{children: ReactNode}> = ({children}) => (
  <Box 
    as="h2"
    fontSize={{base: '1.4em', md: '1.8em'}}
    textAlign="center"
    fontWeight="bold"
    marginBottom="1em"
  >
    {children}
  </Box>

);