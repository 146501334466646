import {VStack} from '@chakra-ui/react';
import {ReactNode} from 'react';

export const VArticle: React.FC<{children: ReactNode}> = ({children}) => (
  <VStack 
    as="article"
    alignItems="center"
    justifyContent="center"
  >
    {children}
  </VStack>
);