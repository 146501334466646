import {Global} from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: "maiandra";
        src: url('/fonts/maiandra.ttf');
      }
    `} />
);