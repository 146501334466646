import {Box, Image} from '@chakra-ui/react';

export const Sep = () => (
  <Box
    textAlign="center"
    padding="20px"
    marginBottom={{base: '1.5em', md: '2em'}}
  >
    <Image src="/images/arabesque.png" alt="separateur" display="inline" />
  </Box>
);