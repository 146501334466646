import {useState} from 'react';
import {HStack, Image, ImageProps, StyleProps} from '@chakra-ui/react';

export interface ZImageProps {
  dir: string,
  src: string,
  alt: string,
  minWidth?: string | {base: string, md: string}

}

export const ZImage: React.FC<ZImageProps> = ({
  dir,
  src,
  alt,
  minWidth
}) => {
  const [zoom, setZoom] = useState(false);

  const onClick = () => {
    setZoom(oldZoom => !oldZoom);
  };

  const stackProps: StyleProps = {
    transition: '0.5s',
    zIndex: 0
  };
  if (zoom) {
    stackProps.position = 'absolute';
    stackProps.top = '0px'; 
    stackProps.left = '0px';
    stackProps.width = '100%';
    stackProps.height = '100vh';
    stackProps.background = 'rgba(0, 0, 0, 0.8)';
    stackProps.zIndex = '100';
  }

  const imageProps: ImageProps = {
    src,
    alt,
    onClick,
    width: dir === 'v' ? '50%' : '90%',
    minWidth: minWidth ?? '300px',
    maxWidth: '1200px',
    height: 'auto',
    margin: 'auto',
    padding: '20px',

  };

  return (
    <HStack {...stackProps}>
      <Image {...imageProps} />
    </HStack>
  );
};