import {Box} from '@chakra-ui/react';
import {H2} from '../common/H2';
import {Sep} from '../common/Sep';
import {VArticle} from '../common/VArticle';
import {decorative} from '../images';
import {ImageRow} from '../common/ImageRow';
import {ZImage} from '../common/ZImage';

export const Decorative = () => (
  <Box as="section" margin="2em auto">
    <VArticle>
      <H2>Plaques de maison / d'entreprise</H2>
      <ImageRow>
        <ZImage {...decorative['plaque01']} />
        <ZImage {...decorative['plaque02']} />
        <ZImage {...decorative['plaque03']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['plaque04']} />
        <ZImage {...decorative['plaque05']} />
        <ZImage {...decorative['plaque09']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['plaque08']} />
        <ZImage {...decorative['plaque06']} />
        <ZImage {...decorative['plaque07']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['plaque10']} />
        <ZImage {...decorative['plaque11']} />
      </ImageRow>
      <Sep />
    </VArticle>
    <VArticle>
      <H2>Numéros de maison</H2>
      <ImageRow>
        <ZImage {...decorative['num02']} />
        <ZImage {...decorative['num03']} />
        <ZImage {...decorative['num04']} />
        <ZImage {...decorative['num05']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['num01']} />
        <ZImage {...decorative['num09']} />
        <ZImage {...decorative['num012']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['num06']} />
        <ZImage {...decorative['num07']} />
        <ZImage {...decorative['num10']} />
        <ZImage {...decorative['num11']} />
      </ImageRow>
      <Sep />
    </VArticle>
    <VArticle>
      <H2>Plaques commémoratives</H2>
      <ImageRow>
        <ZImage {...decorative['com01']} />
        <ZImage {...decorative['com03']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['com04']} />
        <ZImage {...decorative['com02']} />
      </ImageRow>
      <Sep />
    </VArticle>
    <VArticle>
      <H2>Objets de décoration</H2>
      <ImageRow>
        <ZImage {...decorative['deco01']} />
        <ZImage {...decorative['deco02']} />
        <ZImage {...decorative['deco03']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['deco04']} />
        <ZImage {...decorative['deco06']} />
        <ZImage {...decorative['deco17']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['deco07']} />
        <ZImage {...decorative['deco12']} />
        <ZImage {...decorative['deco20']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['deco08']} />
        <ZImage {...decorative['deco14']} />
        <ZImage {...decorative['deco11']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['deco16']} />
        <ZImage {...decorative['deco05']} />
        <ZImage {...decorative['deco18']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['deco10']} />
        <ZImage {...decorative['deco22']} />
        <ZImage {...decorative['deco21']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['deco09']} />
        <ZImage {...decorative['deco19']} />
        <ZImage {...decorative['deco15']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['deco24']} />
        <ZImage {...decorative['deco23']} />
        <ZImage {...decorative['deco25']} />
      </ImageRow>
      <ImageRow>
        <ZImage {...decorative['deco26']} />
        <ZImage {...decorative['deco27']} />
      </ImageRow>
    </VArticle>
  </Box>
);