export default {
  black: {
    pure:'#0000',
    ff:'#191B20',
    text:'#202125'
  },
  white: {
    100:'#FFFFFF',
  },
  yellow: {
    100: '#FCEC4D',
    500: '#C6B403',
    warning: '#FCF3E1',
    warning_text: '#C80'
  },
  red: {
    100: '#BF453C',
    200:'#D54A3B',
    fail:'#E4191B',
    warning:'#E10909'
  },
  blue: {
    50: '#3249C4',
    100: '#18235E', // default
    main_light: '#CCE3F1',
    main: '#0072BA',
    transparency_10: '#0026881A',
    transparency_20: '#00268833',
  },
  lblue: {
    50:'#E3F5FF',
    100: '#E6F1F9', 
    200: '#EDF1FB',
    300: '#DDEBF5',
    transparency_300_60: '#DDEBF599',
  },
  gray: {
    50: '#F8F8F8',
    100: '#F3F3F3',
    200: '#ECECEC',
    400: '#DCDCDC',
    600: '#B1B1B1',
    800: '#6D6C6C',
    900: '#1B1919',
    bg: '#F7F7F7',
    light: '#EBECEE', // default
    dark: '#565D79',
    archange: '#8a92b0',
    archalpha: '#8a92b099',
    archalpha2: '#8a92b0ef'
  },
  green: {
    success: '#2AA948',
  },
  transparent:{
    0:'#FFF0',
    50:'#FFF8'
  },
  purple: {
    link: '#4a4d5a',
    hover: '#887eb1'
  }
};
