import {Box, HStack, Text, VStack} from '@chakra-ui/react';
import {Sep} from '../common/Sep';
import {ZImage} from '../common/ZImage';

import {accueil} from '../images';
import {H2} from '../common/H2';

export const Accueil = () => (
  <Box as="section">
    <Box 
      as="h1" 
      fontSize={{base: '1.5em', md: '2.4em'}}
      margin="1em 0"
      fontWeight="bold"
      className="main"
      textAlign="center"
    >
      Gravure à la main, création ou rénovation sur tout type de pierre
    </Box>
    <HStack 
      as="article"
      flexWrap="wrap"
      justifyContent="center"
      flexDirection={{base: 'column-reverse', md: 'row'}}
    >
      <video src='/videos/videoAccueil.mp4' controls poster="/videos/posterAccueil.jpg"></video>
      <Box>
        <ZImage {...accueil['img001']} />
        <ZImage  {...accueil['img035']} />
      </Box>
    </HStack>
    <Sep />
    <HStack 
      as="article"
      flexWrap="wrap"
      justifyContent="center"
    >
      <ZImage {...accueil['helene005']} />
      <Box 
        width={{base: '80%', md: '50%'}}
        fontSize={{base: '1.1em', md: '1.5em'}} 
        lineHeight="1.3em"
      >
				La gravure sur pierre est un métier ancestral. A ce jour, il fait partie des Métiers d’Art.<br /><br />
        Depuis plus de 20 ans, je prends toujours autant de plaisir à imaginer, dessiner puis graver sur la pierre.<br /><br />
				Chaque réalisation est unique, tant par la nature de la pierre, le style de lettres, le décor, les couleurs que par les envies et les idées de chacun.
      </Box>
    </HStack>
    <Sep />
    <VStack 
      as="article"
      flexWrap="wrap"
      justifyContent="center"
    >
      <H2>Je travaille en atelier comme en extérieur</H2>
      <HStack 
        justifyContent="center"
        flexWrap={{base: 'wrap', md: 'nowrap'}}
      >
        <ZImage {...accueil['helene001']} />
        <ZImage {...accueil['helene002']} />
        <ZImage {...accueil['helene003']} />
        <ZImage {...accueil['helene004']} />
      </HStack>
    </VStack>
    <Sep />
    <VStack 
      as="article"
      flexWrap="wrap"
      justifyContent="center"
    >
      <H2>Il existe différentes techniques de gravure</H2>
      <Box fontSize={{base: '1.1em', md: '1.5em'}}>
        <HStack 
          flexDirection={{base: 'column-reverse', md: 'row'}}
          flexWrap={{base: 'wrap', md: 'nowrap'}}
          justifyContent="space-evenly"
          marginBottom={{base: '1.5em', md: '0'}}
        >
          <Text
            width={{base: '80%', md: '50%'}}
            textAlign={{base: 'center', md: 'left'}}
          >
            La gravure sur pierre à la main
          </Text>
          <ZImage {...accueil['img007']} />
        </HStack>
        <HStack 
          flexDirection={{base: 'column-reverse', md: 'row'}}
          flexWrap={{base: 'wrap', md: 'nowrap'}}
          justifyContent="space-evenly"
          marginBottom={{base: '1.5em', md: '0'}}
        >
          <Text
            width={{base: '80%', md: '50%'}}
            textAlign={{base: 'center', md: 'left'}}
          >
            La litho-gravure
          </Text>
          <ZImage {...accueil['img006']} />
        </HStack>
      </Box>
    </VStack>
    <Sep />
    <HStack 
      as="article"
      flexWrap={{base: 'wrap', md: 'nowrap'}}
      justifyContent="center"
      marginBottom="2em"
    >
      <Box 
        as="h2"
        width={{base: '80%', md: '48%'}}
        fontSize={{base: '1.1em', md: '1.5em'}}
        textAlign={{base: 'center', md: 'left'}}
        fontWeight="bold"
      >
        Les gravures sont réalisées en feuille d'or, en peinture ou laissées nature
      </Box>
      <ZImage {...accueil['folie']} />
    </HStack>
  </Box>
);
