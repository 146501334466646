import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import {Layout} from './components/common/Layout';
import {Accueil} from './components/Accueil/Accueil';

import {Funeraire} from './components/Funeraire/Funeraire';
import {Decorative} from './components/Decorative/Decorative';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Accueil />} />
      <Route index path="/accueil" element={<Accueil />} />
      <Route path="/funeraire" element={<Funeraire />} />
      <Route path="/decorative" element={<Decorative />} />
    </Route>
  )
);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
