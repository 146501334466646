import {Header} from '../Header/Header';
import {Outlet} from 'react-router-dom';
import {Footer} from '../Footer/Footer';
import {Box, VStack} from '@chakra-ui/react';
import {useState} from 'react';
import {BurgerTab} from '../Header/Burger/BurgerTab';

export interface MenuRoute {
  url: string;
  label: string;
}

const items: MenuRoute[] = [
  {url: 'accueil', label: 'Accueil'},
  {url: 'funeraire', label: 'Gravure funéraire'},
  {url: 'decorative', label: 'Gravure décorative'},
];

export const Layout: React.FC = () => {
  const [showTab, setShowTab] = useState(false);
  return (
    <VStack
      width="100%"
      maxWidth="1200px"
      height="100vh"
      justifyContent="space-between"
      margin="0 auto"
      background="url('./images/carrare.jpg') no-repeat fixed center/cover"
    >
      <Header 
        logo='/images/logo.png' 
        title="ARCHANGE GRAVURE" 
        items={items}
        showTab={showTab}
        setShowTab={setShowTab}
      />
      <Box 
        flex={1}
        width="100%" 
        overflow="auto"
      >
        <Outlet />
        <BurgerTab showTab={showTab} setShowTab={setShowTab} items={items} />
      </Box>
      <Footer />
    </VStack>
  );
};