import {extendTheme} from '@chakra-ui/react';
import styles from './styles';
import colors from './colors';
import {breakpoints} from './breakpoints';

const overrides = {
  styles,
  colors,
  breakpoints,
  fonts: {
    body: 'maiandra'
  }
};

export default extendTheme(overrides);