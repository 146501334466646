import {ZImageProps} from './common/ZImage';

export const accueil: Record<string, ZImageProps> = {
  img001: {
    src: '/images/gravure/001.jpg',
    alt: 'img001',
    dir: 'h'
  },
  img035: {
    src: '/images/gravure/035.jpg',
    alt: 'img035',
    dir: 'h'
  },
  helene005: {
    src: '/images/gravure/helene005.jpg',
    alt: 'helene005',
    dir: 'h'
  },
  helene001: {
    src: '/images/gravure/helene001.jpg',
    alt: 'helene001',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },
  helene002: {
    src: '/images/gravure/helene002.jpg',
    alt: 'helene002',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },
  helene003: {
    src: '/images/gravure/helene003.jpg',
    alt: 'helene003',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },
  helene004: {
    src: '/images/gravure/helene004.jpg',
    alt: 'helene004',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },
  img007: {
    src: '/images/gravure/007.jpg',
    alt: 'img007',
    dir: 'h'
  },
  img006: {
    src: '/images/gravure/006.jpg',
    alt: 'img006',
    dir: 'h'
  },
  folie: {
    src: '/images/gravure/folie.jpg',
    alt: 'folie',
    dir: 'h'
  }
};

export const funeraire: Record<string, ZImageProps> = {
  img002: {
    src: '/images/gravure/002.jpg',
    alt: 'img002',
    dir: 'h'
  },
  img009: {
    src: '/images/gravure/009.jpg',
    alt: 'img009',
    dir: 'v'
  },
  img008: {
    src: '/images/gravure/008.jpg',
    alt: 'img008',
    dir: 'h'
  },

  img010: {
    src: '/images/gravure/010.jpg',
    alt: 'img010',
    dir: 'h'
  },
  img011: {
    src: '/images/gravure/011.jpg',
    alt: 'img011',
    dir: 'h'
  },
  img012: {
    src: '/images/gravure/012.jpg',
    alt: 'img012',
    dir: 'h'
  },

  img013: {
    src: '/images/gravure/013.jpg',
    alt: 'img013',
    dir: 'h'
  },
  img014: {
    src: '/images/gravure/014.jpg',
    alt: 'img014',
    dir: 'h'
  },

  img015: {
    src: '/images/gravure/015.jpg',
    alt: 'img015',
    dir: 'v'
  },
  img016: {
    src: '/images/gravure/016.jpg',
    alt: 'img016',
    dir: 'v'
  },
  img017: {
    src: '/images/gravure/017.jpg',
    alt: 'img017',
    dir: 'v'
  },

  img018: {
    src: '/images/gravure/018.jpg',
    alt: 'img018',
    dir: 'v'
  },
  img019: {
    src: '/images/gravure/019.jpg',
    alt: 'img019',
    dir: 'v'
  },

  imgn13: {
    src: '/images/gravure/n13.jpg',
    alt: 'imgn13',
    dir: 'h'
  },
  img020: {
    src: '/images/gravure/020.jpg',
    alt: 'img020',
    dir: 'h'
  },
  img021: {
    src: '/images/gravure/021.jpg',
    alt: 'img021',
    dir: 'h'
  },

  img022: {
    src: '/images/gravure/022.jpg',
    alt: 'img022',
    dir: 'v'
  },
  img041: {
    src: '/images/gravure/041.jpg',
    alt: 'img041',
    dir: 'h'
  },
  img023: {
    src: '/images/gravure/023.jpg',
    alt: 'img023',
    dir: 'v'
  },

  img024: {
    src: '/images/gravure/024.jpg',
    alt: 'img024',
    dir: 'h'
  },
  img025: {
    src: '/images/gravure/025.jpg',
    alt: 'img025',
    dir: 'h'
  },
  img040: {
    src: '/images/gravure/040.jpg',
    alt: 'img040',
    dir: 'h'
  },

  img044: {
    src: '/images/gravure/044.jpg',
    alt: 'img044',
    dir: 'h'
  },
  plaque12: {
    src: '/images/gravure/plaque12.jpg',
    alt: 'plaque12',
    dir: 'h'
  },

  img026: {
    src: '/images/gravure/026.jpg',
    alt: 'img026',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}
  },
  imgn07: {
    src: '/images/gravure/n07.jpg',
    alt: 'imgn07',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn08: {
    src: '/images/gravure/n08.jpg',
    alt: 'imgn08',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },
  img027: {
    src: '/images/gravure/027.jpg',
    alt: 'img027',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },

  img029: {
    src: '/images/gravure/029.jpg',
    alt: 'img029',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn02: {
    src: '/images/gravure/n02.jpg',
    alt: 'imgn02',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },
  img028: {
    src: '/images/gravure/028.jpg',
    alt: 'img028',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn14: {
    src: '/images/gravure/014.jpg',
    alt: 'imgn14',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },

  imgn09: {
    src: '/images/gravure/n09.jpg',
    alt: 'imgn09',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn15: {
    src: '/images/gravure/n15.jpg',
    alt: 'imgn15',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn18: {
    src: '/images/gravure/n18.jpg',
    alt: 'imgn18',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn10: {
    src: '/images/gravure/n10.jpg',
    alt: 'imgn10',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },

  imgn19: {
    src: '/images/gravure/n19.jpg',
    alt: 'imgn19',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn11: {
    src: '/images/gravure/n11.jpg',
    alt: 'imgn11',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn03: {
    src: '/images/gravure/n03.jpg',
    alt: 'imgn03',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn21: {
    src: '/images/gravure/n21.jpg',
    alt: 'imgn21',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },

  imgn24: {
    src: '/images/gravure/n24.jpg',
    alt: 'imgn24',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn25: {
    src: '/images/gravure/n25.jpg',
    alt: 'imgn25',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn22: {
    src: '/images/gravure/n22.jpg',
    alt: 'imgn22',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },
  imgn26: {
    src: '/images/gravure/n26.jpg',
    alt: 'imgn26',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },

  totem: {
    src: '/images/gravure/totem.jpg',
    alt: 'totem',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}

  },
  img039: {
    src: '/images/gravure/039.jpg',
    alt: 'img039',
    dir: 'v',
    minWidth: {base: '300px', md: '250px'}

  },

  img030: {
    src: '/images/gravure/030.jpg',
    alt: 'img030',
    dir: 'v'
  },
  img034: {
    src: '/images/gravure/034.jpg',
    alt: 'img034',
    dir: 'h'
  },
  img038: {
    src: '/images/gravure/038.jpg',
    alt: 'img038',
    dir: 'v'
  },

  img032: {
    src: '/images/gravure/032.jpg',
    alt: 'img032',
    dir: 'h'
  },
  img033: {
    src: '/images/gravure/033.jpg',
    alt: 'img033',
    dir: 'h'
  },

  img042: {
    src: '/images/gravure/042.jpg',
    alt: 'img042',
    dir: 'h'
  },
  img043: {
    src: '/images/gravure/043.jpg',
    alt: 'img043',
    dir: 'h'
  },

  img036: {
    src: '/images/gravure/036.jpg',
    alt: 'img036',
    dir: 'v'
  },
  img037: {
    src: '/images/gravure/037.jpg',
    alt: 'img037',
    dir: 'v'
  },
  img031: {
    src: '/images/gravure/031.jpg',
    alt: 'img031',
    dir: 'v'
  },
};

export const decorative: Record<string, ZImageProps> = {
  plaque01: {
    src: '/images/gravure/plaque01.jpg',
    alt: 'plaque01',
    dir: 'h'
  },
  plaque02: {
    src: '/images/gravure/plaque02.jpg',
    alt: 'plaque02',
    dir: 'h'
  },
  plaque03: {
    src: '/images/gravure/plaque03.jpg',
    alt: 'plaque03',
    dir: 'h'
  },

  plaque04: {
    src: '/images/gravure/plaque04.jpg',
    alt: 'plaque04',
    dir: 'h'
  },
  plaque05: {
    src: '/images/gravure/plaque05.jpg',
    alt: 'plaque05',
    dir: 'h'
  },
  plaque09: {
    src: '/images/gravure/plaque09.jpg',
    alt: 'plaque09',
    dir: 'h'
  },

  plaque08: {
    src: '/images/gravure/plaque08.jpg',
    alt: 'plaque08',
    dir: 'h'
  },
  plaque06: {
    src: '/images/gravure/plaque06.jpg',
    alt: 'plaque06',
    dir: 'h'
  },
  plaque07: {
    src: '/images/gravure/plaque07.jpg',
    alt: 'plaque07',
    dir: 'h'
  },

  plaque10: {
    src: '/images/gravure/plaque10.jpg',
    alt: 'plaque10',
    dir: 'h'
  },
  plaque11: {
    src: '/images/gravure/plaque11.jpg',
    alt: 'plaque11',
    dir: 'h'
  },

  num02: {
    src: '/images/gravure/num02.jpg',
    alt: 'num02',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}
  },
  num03: {
    src: '/images/gravure/num03.jpg',
    alt: 'num03',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}
  },
  num04: {
    src: '/images/gravure/num04.jpg',
    alt: 'num04',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}
  },
  num05: {
    src: '/images/gravure/num05.jpg',
    alt: 'num05',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}
  },

  num01: {
    src: '/images/gravure/num01.jpg',
    alt: 'num01',
    dir: 'h'
  },
  num09: {
    src: '/images/gravure/num09.jpg',
    alt: 'num09',
    dir: 'h'
  },
  num12: {
    src: '/images/gravure/num12.jpg',
    alt: 'num12',
    dir: 'h'
  },

  num06: {
    src: '/images/gravure/num06.jpg',
    alt: 'num06',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}
  },
  num07: {
    src: '/images/gravure/num07.jpg',
    alt: 'num07',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}
  },
  num10: {
    src: '/images/gravure/num10.jpg',
    alt: 'num10',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}
  },
  num11: {
    src: '/images/gravure/num11.jpg',
    alt: 'num11',
    dir: 'h',
    minWidth: {base: '300px', md: '250px'}
  },

  com01: {
    src: '/images/gravure/com01.jpg',
    alt: 'com01',
    dir: 'h'
  },
  com03: {
    src: '/images/gravure/com03.jpg',
    alt: 'com03',
    dir: 'h'
  },

  com04: {
    src: '/images/gravure/com04.jpg',
    alt: 'com04',
    dir: 'h'
  },
  com02: {
    src: '/images/gravure/com02.jpg',
    alt: 'com02',
    dir: 'h'
  },

  deco01: {
    src: '/images/gravure/deco01.jpg',
    alt: 'deco01',
    dir: 'h'
  },
  deco02: {
    src: '/images/gravure/deco02.jpg',
    alt: 'deco02',
    dir: 'h'
  },
  deco03: {
    src: '/images/gravure/deco03.jpg',
    alt: 'deco03',
    dir: 'h'
  },

  deco04: {
    src: '/images/gravure/deco04.jpg',
    alt: 'deco04',
    dir: 'h'
  },
  deco06: {
    src: '/images/gravure/deco06.jpg',
    alt: 'deco06',
    dir: 'v'
  },
  deco17: {
    src: '/images/gravure/deco17.jpg',
    alt: 'deco17',
    dir: 'h'
  },

  deco07: {
    src: '/images/gravure/deco07.jpg',
    alt: 'deco07',
    dir: 'h'
  },
  deco12: {
    src: '/images/gravure/deco12.jpg',
    alt: 'deco12',
    dir: 'v'
  },
  deco20: {
    src: '/images/gravure/deco20.jpg',
    alt: 'deco20',
    dir: 'h'
  },
  
  deco08: {
    src: '/images/gravure/deco08.jpg',
    alt: 'deco08',
    dir: 'h'
  },
  deco14: {
    src: '/images/gravure/deco14.jpg',
    alt: 'deco14',
    dir: 'v'
  },
  deco11: {
    src: '/images/gravure/deco11.jpg',
    alt: 'deco11',
    dir: 'h'
  },
  
  deco16: {
    src: '/images/gravure/deco16.jpg',
    alt: 'deco16',
    dir: 'h'
  },
  deco05: {
    src: '/images/gravure/deco05.jpg',
    alt: 'deco05',
    dir: 'v'
  },
  deco18: {
    src: '/images/gravure/deco18.jpg',
    alt: 'deco18',
    dir: 'h'
  },

  deco10: {
    src: '/images/gravure/deco10.jpg',
    alt: 'deco10',
    dir: 'h'
  },
  deco22: {
    src: '/images/gravure/deco22.jpg',
    alt: 'deco22',
    dir: 'h'
  },
  deco21: {
    src: '/images/gravure/deco21.jpg',
    alt: 'deco21',
    dir: 'h'
  },

  deco09: {
    src: '/images/gravure/deco09.jpg',
    alt: 'deco09',
    dir: 'h'
  },
  deco19: {
    src: '/images/gravure/deco19.jpg',
    alt: 'deco19',
    dir: 'h'
  },
  deco15: {
    src: '/images/gravure/deco15.jpg',
    alt: 'deco15',
    dir: 'h'
  },

  deco24: {
    src: '/images/gravure/deco24.jpg',
    alt: 'deco24',
    dir: 'v'
  },
  deco23: {
    src: '/images/gravure/deco23.jpg',
    alt: 'deco23',
    dir: 'v'
  },
  deco25: {
    src: '/images/gravure/deco25.jpg',
    alt: 'deco25',
    dir: 'v'
  },

  deco26: {
    src: '/images/gravure/deco26.jpg',
    alt: 'deco26',
    dir: 'v'
  },
  deco27: {
    src: '/images/gravure/deco27.jpg',
    alt: 'deco27',
    dir: 'v'
  },
};