import {Box, HStack} from '@chakra-ui/react';
import {MenuItem} from './MenuItem';
import {MenuRoute} from '../common/Layout';

export const Menu: React.FC<{items: MenuRoute[]}> = ({items}) => (
  <Box
    display={{base: 'none', md: 'block'}}
    width="80%"
    margin="auto"
    height="50%"
  >
    <HStack
      as="ul"
      alignItems="center"
      justifyContent="space-evenly"
    >
      {
        items.map((item, id) => (
          <MenuItem key={id} item={item} />
        ))
      }
    </HStack>
  </Box>

);