export default {
  global: {
    'html, body': {
      fontFamily: 'maiandra',
      fontSize: '16px',
      color: 'purple.link',
      margin: '0px',
      padding: '0px',
      backgroundColor: 'white'
    } 
  },
};
