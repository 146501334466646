import {HStack} from '@chakra-ui/react';
import {ReactNode} from 'react';

export const ImageRow: React.FC<{children: ReactNode}> = ({children}) => (
  <HStack
    alignItems="center"
    justifyContent="center"
    flexWrap={{base: 'wrap', md: 'nowrap'}}
  >
    {children}
  </HStack>
);