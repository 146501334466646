import {Box, Link} from '@chakra-ui/react';
import {NavLink} from 'react-router-dom';
import {MenuRoute} from '../common/Layout';

export const MenuItem: React.FC<{
  item: MenuRoute
}> = ({item}) => (
  <Box
    as="li"
    textAlign="center"
    listStyleType="none"
    fontSize={{md: '1em', lg: '1.4em'}}
    margin="auto"
  >
    <Link
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      as={(props: any) => (
        <NavLink
          {...props}
          style={({isActive}) => {
            return {
              textShadow: isActive || (item.url === 'accueil' && location.pathname === '/') ? 
                '2px 0 2px rgba(184, 174, 225, 0.5), -2px 0 2px rgba(184, 174, 225, 0.5), 0 2px 2px rgba(184, 174, 225, 0.5), 0 -2px 2px rgba(184, 174, 225, 0.5), 2px 2px 2px rgba(184, 174, 225, 0.5), -2px -2px 2px rgba(184, 174, 225, 0.5), 2px -2px 2px rgba(184, 174, 225, 0.5), -2px 2px 2px rgba(184, 174, 225, 0.5)' :
                '1px 0 1px white, -1px 0 1px white, 0 1px 1px white, 0 -1px 1px white, 1px 1px 1px white, -1px -1px 1px white, 1px -1px 1px white, -1px 1px 1px white',
              textDecoration: 'none',
            };
          }}
        />
      )} 
      to={`/${item.url}`} 
      color="purple.link"
      _hover={{
        color: 'purple.hover'
      }}
    >
      {item.label}
    </Link>		    
  </Box>
);